@import 'bootstrap-functions.scss';
@import 'navigation-icons.scss';

/* previous oldadmin.css */
.arrow {
    float: right;
    line-height: 1.42857;
}

.plus-times {
    float: right;
}


.plus-minus {
    float: right;
}


.dataTables_wrapper {
    position: relative;
    clear: both;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc, 
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    background: 0 0;
}
table.dataTable thead .sorting_asc:after {
    content: "";
    float: right;
    font-family: fontawesome;
}
table.dataTable thead .sorting_desc:after {
    content: "";
    float: right;
    font-family: fontawesome;
}
table.dataTable thead .sorting:after {
    content: "";
    float: right;
    font-family: fontawesome;
    color: rgba(50, 50, 50, 0.5);
}
.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}
.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
} 
.huge {
    font-size: 32px;
}
.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all 0.5s;
}
.btn-primary.btn-outline {
    color: #428bca;
}
.btn-success.btn-outline {
    color: #5cb85c;
}
.btn-info.btn-outline {
    color: #5bc0de;
}
.btn-warning.btn-outline {
    color: #f0ad4e;
}
.btn-danger.btn-outline {
    color: #d9534f;
}
.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.panel .slidedown .glyphicon,
.chat .glyphicon {
    margin-right: 5px;
}
.chat-panel .panel-body {
    height: 350px;
    overflow-y: scroll;
}
.login-panel {
    margin-top: 25%;
}
.flot-chart {
    display: block;
    height: 400px;
}
.flot-chart-content {
    width: 100%;
    height: 100%;
}

.modal-open {
    overflow: hidden
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0
}

.modal.fade .modal-dialog {
    -webkit-transform: translate(0,-25%);
    -ms-transform: translate(0,-25%);
    -o-transform: translate(0,-25%);
    transform: translate(0,-25%);
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out,-o-transform .3s ease-out
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    transform: translate(0,0)
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}
/*
.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px
}

.modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000
}

.modal-backdrop.fade {
    filter: alpha(opacity=0);
    opacity: 0
}

.modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5
}

.modal-header .close {
    margin-top: -2px
}

.modal-title {
    margin: 0;
    line-height: 1.42857143
}

.modal-body {
    position: relative;
    padding: 15px
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5
}

.modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px
}

.modal-footer .btn-group .btn + .btn {
    margin-left: -1px
}

.modal-footer .btn-block + .btn-block {
    margin-left: 0
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media(min-width:768px) {
    .modal-dialog {
        width: 600px;
        margin: 30px auto
    }

    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5); 
        box-shadow: 0 5px 15px rgba(0,0,0,.5)
    }

    .modal-sm {
        width: 300px
    }
} */
/*
 end of previous oldadmin.css 
 */
body {
    font-family: "Noto Sans",sans-serif;
}

.center-block {
    margin: 0 auto;
}

.img-repsonsive {
    max-width: 100%;
    height: auto;
}

#login-background {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
}

main {
    background-color: white;
    margin-top: 15px;
}

#sidebar-nav {
    margin-top: 50px;

    .dropdown-menu.show {
        display: block;
        position: static !important;
        padding: 0;
        border: 0;
        background-color: transparent;
        transform: none !important;
        width: 100%;

        li {
            width: 100%;
            border-bottom: 0;

            a {
                width: 100%;
                padding: 0.25rem;
                font-weight: normal;
                display: inline-block;
                padding-left: 2.5rem;

                &:hover {
                    background-color: rgba(0,0,0, 0.025);
                    text-decoration: none;
                }
            }
        }
    }

    .nav-item {
        width: 100%;
    }

    .dropdown-toggle {
        width: 100%;
        displaY: block;
        padding: 0.5rem;

        &.active {
            background-color: transparent;
        }

        &:hover {
            background-color: rgba(0,0,0, 0.025);
            text-decoration: none;
        }
    }

    a.active {
        background-color: transparent;
    }

    .dropdown-toggle:after {
        display: block;
        margin-left: .255em;
        vertical-align: .255em;
        float: right;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
        margin-top: 6px;
    }
}

#claim-selection {
    &.show, &.collapsing {
        position: absolute;
        background-color: white;
    }

    font-size: 14px;
    color: #4e4e4e;
}

.group-claims {
    table {
        border: 1px solid #e7e7e7;

        td {
            padding: 0.5rem 0;

            &:last-child {
                padding-right: 0.5rem;
            }
        }
    }
}

a {
    color: #337ab7;
}

.sidebar {
    width: auto;
}

.dashboard .card {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

#claim-selection-button.dropdown-toggle::after {
    color: #4e4e4e;
    font-size: 1rem;
    margin-left: .5em;
}

.card-primary {
    border: 1px solid #337ab7;

    .card-header {
        background-color: #337ab7;
        color: white;
    }
}

.card-green {
    border-color: #5cb85c;
}

.card-green .card-header {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.card-green a {
    color: #5cb85c;
}

.card-green a:hover {
    color: #3d8b3d;
}

.card-darkgreen {
    border-color: #4C994C;
}

.card-darkgreen .card-header {
    border-color: #4C994C;
    color: #fff;
    background-color: #4C994C;
}

.card-darkgreen a {
    color: #4C994C;
}

.card-darkgreen a:hover {
    color: #5cb85c;
}

.card-red {
    border-color: #d9534f;
}

.card-red .card-header, .card-red.card-header {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.card-red a {
    color: #d9534f;
}

.card-red a:hover {
    color: #b52b27;
}

.card-yellow {
    border-color: #f0ad4e;
}

.card-yellow .card-header {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.card-yellow a {
    color: #f0ad4e;
}

.card-yellow a:hover {
    color: #df8a13;
}

.card-purple {
    border-color: #C684ED;
}

.card-purple .card-header {
    border-color: #C684ED;
    color: #fff;
    background-color: #C684ED;
}

.card-purple a {
    color: #C684ED;
}

.card-purple a:hover {
    color: #9E41D3;
}

.sidebar .sidebar-search {
    padding: .5rem 0;
}

#page-wrapper {
    position: inherit;
    //margin: 0 0 0 250px;
    margin: 0;
    //padding: 0 30px;
    padding: 0;
    //border-left: 1px solid #e7e7e7;
    border-left: 0;
    background-color: #fff;
}

#dateRange {
    font-weight: bold;
    text-align: center;
    max-width: 300px;
    font-size: 1.1rem;
}

.dataTables_wrapper {
    .dropdown-item.active, .dropdown-item:active {
        background-color: #337ab7 !important;
        color: #FFF !important;

        &:hover {
            background-color: #286090 !important;
        }
    }

    div.dt-button-collection div.dropdown-menu {
        background-color: #f8f9fa;
        color: #212529;
    }
}

.edit-table td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .badge {
        color: white;
        padding: 0.35rem 0.5rem;
        border-radius: 1rem;
    }
}

.datepicker-days table tr td.active {
    background-image: none !important;
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: 0;
}

.sidebar {
    @media (min-width: 768px) {
        position: static;
    }
}

@media (min-width: 2560px) {
    #sidebar-nav {
        width: 250px;
        flex: 0 0 250px;
    }

    #partner-content {
        width: auto;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
}

h3 {
    font-size: 1.5rem;
}

.subtext {
    font-weight: bold;
}

// Timeline

.timeline-centered {
    position: relative;
    margin-bottom: 30px;
}

.timeline-centered:before, .timeline-centered:after {
    content: " ";
    display: table;
}

.timeline-centered:after {
    clear: both;
}

.timeline-centered:before, .timeline-centered:after {
    content: " ";
    display: table;
}

.timeline-centered:after {
    clear: both;
}

.timeline-centered:before {
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    background: #f5f5f6;
    /*left: 50%;*/
    top: 20px;
    bottom: 20px;
    margin-left: 30px;
}

.timeline-centered .timeline-entry {
    position: relative;
    /*width: 50%;
        float: right;*/
    margin-top: 5px;
    margin-left: 30px;
    margin-bottom: 20px;
    clear: both;
}

.timeline-centered .timeline-entry:before, .timeline-centered .timeline-entry:after {
    content: " ";
    display: table;
}

.timeline-centered .timeline-entry:after {
    clear: both;
}

.timeline-centered .timeline-entry:before, .timeline-centered .timeline-entry:after {
    content: " ";
    display: table;
}

.timeline-centered .timeline-entry:after {
    clear: both;
}

.timeline-centered .timeline-entry.begin {
    margin-bottom: 0;
}

.timeline-centered .timeline-entry.left-aligned {
    float: left;
}

.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner {
    margin-left: 0;
    margin-right: -18px;
}

.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
    left: auto;
    right: -100px;
    text-align: left;
}

.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-icon {
    float: right;
}

.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label {
    margin-left: 0;
    margin-right: 70px;
}

.timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label:after {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -9px;
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.timeline-centered .timeline-entry .timeline-entry-inner {
    position: relative;
    margin-left: -20px;
}

.timeline-centered .timeline-entry .timeline-entry-inner:before, .timeline-centered .timeline-entry .timeline-entry-inner:after {
    content: " ";
    display: table;
}

.timeline-centered .timeline-entry .timeline-entry-inner:after {
    clear: both;
}

.timeline-centered .timeline-entry .timeline-entry-inner:before, .timeline-centered .timeline-entry .timeline-entry-inner:after {
    content: " ";
    display: table;
}

.timeline-centered .timeline-entry .timeline-entry-inner:after {
    clear: both;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time {
    position: absolute;
    left: -100px;
    text-align: right;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span {
    display: block;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:first-child {
    font-size: 15px;
    font-weight: bold;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:last-child {
    font-size: 12px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
    background: #fff;
    color: #737881;
    display: block;
    width: 40px;
    height: 40px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    text-align: center;
    -moz-box-shadow: 0 0 0 5px #f5f5f6;
    -webkit-box-shadow: 0 0 0 5px #f5f5f6;
    box-shadow: 0 0 0 5px #f5f5f6;
    line-height: 40px;
    font-size: 15px;
    float: left;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-primary {
    background-color: #303641;
    color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-secondary {
    background-color: #ee4749;
    color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-success {
    background-color: #00a651;
    color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-info {
    background-color: #21a9e1;
    color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-warning {
    background-color: #fad839;
    color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-danger {
    background-color: #cc2424;
    color: #fff;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
    position: relative;
    background: #f5f5f6;
    padding: 1em;
    margin-left: 60px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 9px 9px 0;
    border-color: transparent #f5f5f6 transparent transparent;
    left: 0;
    top: 10px;
    margin-left: -9px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p {
    color: #737881;
    /*font-family: "Noto Sans",sans-serif;*/
    /*font-size: 12px;*/
    margin: 0;
    line-height: 1.428571429;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p + p {
    margin-top: 15px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 a {
    color: #303641;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 span {
    -webkit-opacity: .6;
    -moz-opacity: .6;
    opacity: .6;
    -ms-filter: alpha(opacity=60);
    filter: alpha(opacity=60);
}

.timeline-footer {
    text-align: right;
    color: #737881;
    font-size: 0.8rem;
    font-style: italic;
    border: 1px solid #f5f5f6;
    background-color: #FFF;
    padding: 5px 10px;
    margin-top: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    position: absolute;
    top: -22px;
    right: -15px;
}

.page-heading {
    border-bottom: 1px solid #CCC;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

#col-filters {
    padding: .5rem;
    padding-left: 0;
    min-width: 250px;

    hr {
        margin: 15px 0px 10px;
    }

    .category-collapse-btn, .filter-collapse-btn {
        width: 100%;
        text-align: left;
        padding: 6px 0px;
        font-weight: bold;
    }
}

.collapse-categories-content {
    max-height: 240px;
    overflow-y: scroll
}

.filterable-item {
    .item-data-box {
        border-top: 1px solid #CCC;
        margin-top: 10px;
        color: #777;

        > div {
            font-size: 0.7rem;
            color: #777;
            font-style: italic;
        }
    }
}

#saveFooterBar {
    box-shadow: 0 -3px 3px rgba(0,0,0,0.15);

    > .row {
        padding: 10px;
        background-color: #EEE;
        height: 58px;
    }
}

.loading-container {
    transition: all .75s;

    .loading-blocker {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255,255,255, 0.5);
        opacity: 0;
        z-index: 5;
        transition: all 1s;

        &.show {
            opacity: 1;
        }

        > div {
            position: fixed;
            top: 50%;
            width: 96.5%;
            text-align: center;
            margin-top: -15px;
            font-weight: bold;
            font-size: 30px;
            color: rgba(0,0,0,0.8);
            ;
            text-shadow: 0 3px 3px rgba(0,0,0,0.25);
            color: #008FD2;
        }
    }
}

.badge-pill.pf-badge {
    padding: .5em .7em;
}

#page-wrapper.filterable-picker-container {
    margin-bottom: 50px; // for bottom bar
}

.pf-content {
    padding: .55rem;
    padding-top: .9rem;

    .pf-content-head {
        padding-left: .5rem;
        margin-bottom: .75rem;
    }
}

.pickr-container {
    position: relative;
    width: 55px;
    margin: 0 5px;
    cursor: pointer;
    display: inline-block;

    &:first-child {
        margin-left: 0;
    }
}

.pickr-swatch {
    width: 55px;
    height: 55px;
    display: block;
    text-align: center;
    padding-top: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    box-sizing: border-box;
    margin: 2px 5px;
    cursor: pointer;
    border: 1px solid #aaa;
}

.product-size-box {
    border: 1px solid #CCC;
    text-align: center;
    float: left;
    margin: 5px;
    width: 45px;
    height: 45px;
    line-height: 1rem;
    cursor: pointer;
    background-color: #999;
    border-color: #ddd;
    transition: .5s all;
    box-shadow: 0 3px 3px rgba(0,0,0,0.25);

    &.active {
        background-color: white;
        border: 1px solid #CCC;
    }
}

.pd-color-box {
    width: 18px;
    height: 18px;
    /*margin: 5px;*/
    transition: .5s all;
    /*filter: blur(1px);*/
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0 3px 3px rgba(0,0,0,0.25);
    margin-top: -5px;

    &.active {
        border-radius: 0;
        opacity: 1;
        filter: none;
    }
}

.instant-checkbox {
    display: inline-block;
    margin: 0;

    input {
        display: none;
    }
}

.pd-color-box-container {
    position: relative;
    display: inline-block;

    .material-icons {
        color: red;
        border-radius: 100px;
        background-color: white;
        opacity: 0.8;
        box-shadow: 0 3px 3px rgba(0,0,0,0.25);
        position: absolute;
        top: 0;
        right: 0;
        display: block;
    }

    .pd-color-box {
        border-radius: 0;
        opacity: 1;
        filter: none;
    }

    &.active .material-icons {
        display: none;
    }
}

.toggle.ios, .toggle-on.ios, .toggle-off.ios {
    border-radius: 20rem;
}

.toggle.ios .toggle-handle {
    border-radius: 20rem;
}

.dropdown-toggle:after {
    content: none;
}

input.text-right[type='text']::placeholder {
    text-align: right; /* for Chrome, Firefox, Opera */
}

.text-right:-ms-input-placeholder {
    text-align: right; /* for IE 10-11 */
}

.text-right::-webkit-input-placeholder {
    text-align: right; /* for IE Edge */
}

/* HTML Radio Box */
:root {
    --card-line-height: 1.2em;
    --card-padding: 1em;
    --card-radius: 0.5em;
    --color-blue: rgb(51, 122, 183);
    --color-gray: #e2ebf6;
    --color-dark-gray: #c4d1e1;
    --color-selected: #212529;
    /*--color-unselected: #c4d1e1;*/
    --color-unselected: #888;
    --radio-border-width: 2px;
    --radio-size: 1.5em;
}

.radio-card {
    background-color: #fff;
    border-radius: var(--card-radius);
    position: relative;

    &:hover {
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    }
}

.radio-card .radio {
    font-size: inherit;
    margin: 0;
    position: absolute;
    right: calc(var(--card-padding) + var(--radio-border-width));
    top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) {
    .radio-card .radio {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: #fff;
        border: var(--radio-border-width) solid var(--color-gray);
        border-radius: 50%;
        cursor: pointer;
        height: var(--radio-size);
        outline: none;
        transition: background 0.2s ease-out, border-color 0.2s ease-out;
        width: var(--radio-size);

        &::after {
            border: var(--radio-border-width) solid #fff;
            border-top: 0;
            border-left: 0;
            content: '';
            display: block;
            height: 0.75rem;
            left: 25%;
            position: absolute;
            top: 50%;
            transform: rotate(45deg) translate(-50%, -50%);
            width: 0.375rem;
        }

        &:checked {
            background: var(--color-blue);
            border-color: var(--color-blue);
        }
    }

    .radio-card:hover .radio {
        border-color: var(--color-dark-gray);

        &:checked {
            border-color: var(--color-blue);
        }
    }
}

.radio-body {
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: var(--card-radius);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: var(--card-padding);
    transition: border-color 0.2s ease-out;
    color: var(--color-unselected);
}

.radio-card .radio-card:hover .radio-body {
    border-color: var(--color-dark-gray);
}

.radio-card .radio:checked ~ .radio-body {
    border-color: var(--color-blue);
    color: var(--color-selected);
}

.radio-card .radio:checked ~ .radio-body .radio-title {
    color: var(--color-blue);
}

.radio-card .radio:focus ~ .radio-body {
    box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio-card .radio:disabled ~ .radio-body {
    color: var(--color-dark-gray);
    cursor: default;
}

.radio-card:hover .radio:disabled ~ .radio-body {
    border-color: var(--color-gray);
    box-shadow: none;
}

.radio-card:hover .radio:disabled {
    border-color: var(--color-gray);
}

.radio-title {
    font-size: 20px;
    font-weight: 500;
}

.autocomplete-results {
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: absolute;
    top: 38px;
    left: 0;
    width: 100%;
    z-index: 10002;
    border: 1px solid #333;
    border-top: 0;

    li {
        padding: 5px 10px;
        border-top: 1px solid #333333;
        background-color: rgba(255,255,255,0.95);
        cursor: pointer;

        a {
            display: block;
            color: black;
            text-decoration: none;
        }

        .result-header {
            font-weight: bold;
        }

        .result-text {
            display: inline-block;
            vertical-align: middle;
        }

        img {
            display: inline-block;
            height: 70px;
        }
    }

    li.active, li:hover {
        background-color: rgba(240,240,240,1);
    }
}

.autocomplete-loading {
    background-image: url("//dynamic-cdn.azureedge.net/theme/Shared/images/ajax-loader.gif");
    background-repeat: no-repeat;
    background-position: 95% center;
    font-size: 22px;
    font-weight: bold;
    color: var(--color-unselected);
    margin-bottom: 5px;
}

.chart-card-body::before {
    content: "";
    background-image: url('//dynamic-cdn.azureedge.net/theme/Shared/images/dw-pattern-test2.png');
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.15;
}

.dropdown-notifications {
    /*box-shadow: 0 0 5px rgba(0,0,0,0.3);*/
}

.dropdown-notifications .notification {
    color: #212529;
    border-top: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
}

/*.dropdown-notifications .notification:hover {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}*/

.dropdown-notifications a:hover {
    text-decoration: none;
}

.dropdown-notifications .notification-header {
    background-color: #F5F5F6;
    padding: 10px 20px;
    font-weight: bold;
    border-bottom: 1px solid #DDD;
}

.notification-icon {
    color: #fff;
    display: block;
    width: 40px;
    height: 40px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    text-align: center;
    -moz-box-shadow: 0 0 0 5px #f5f5f6;
    -webkit-box-shadow: 0 0 0 5px #f5f5f6;
    box-shadow: 0 0 0 5px #f5f5f6;
    line-height: 40px;
    font-size: 15px;
    float: left;
    margin-right: 15px;
}

.impersonate-badge {
    position: absolute;
    bottom: 0;
    left: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #FFF;
    border: 1px solid black;
    border-bottom: 0px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    padding: 5px;
    font-size: 12pt;
}

.btn-primary {
    background-color: #337ab7;
    border-color: #337ab7;
    color: #ffffff;
}

.btn-primary:hover {
    background-color: #286090;
    border-color: #204d74;
    color: #ffffff;
}

.btn-primary.btn-outline {
    background-color: transparent;
    border-color: #337ab7;
}

.btn-primary.btn-outline:hover {
    color: #337ab7;
}

/* Color Picker */
.picker-button {
    display: inline-block;
    position: relative;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.picker-button:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    box-shadow: inset 0 0 0 2px #D4D4D8, inset 0 0 0 4px #FFF;
}

.picker-button:disabled,
.picker-button[disabled] {
    border: 1px solid #E9ECEF;
}

.picker-input:disabled,
.picker-input[disabled] {
}

#modalColorPicker {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2500;
    width: 400px;
    /*height: 550px;*/
    border: 1px solid black;
    display: none;
}

#colorCanvasContainer {
    height: 200px;
    width: 100%;
    border: 2px solid #D4D4D4;
    border-radius: 10px;
}

#colorCanvas {
    height: 100%;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    border: 2px solid white;
}

#hueCanvasContainer {
    height: 25px;
    width: 100%;
    border: 2px solid #D4D4D4;
    border-radius: 20px;
}

#hueCanvas {
    height: 100%;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    border: 2px solid white;
}

.baseHandle {
    border: 2px solid white;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    position: absolute;
    pointer-events: none;
    box-sizing: border-box;
    display: none;
}

.baseHandle div {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    border: 1px solid black;
    position: relative;
    top: -2px;
    left: -2px;
}

.tallHandle {
    display: block !important;
    height: 20px !important;
    border-radius: 5px !important;
    position: relative !important;
    top: -26px;
    left: 169px;
}

.tallHandle div {
    height: 20px !important;
    border-radius: 5px !important;
}

.colorSwatch {
    margin: 2px 2px;
}

.colorSwatch button {
}

.colorSwatchFloatLeft {
    display: inline;
    position: relative;
    top: 0px;
    left: -10px;
}

.colorSwatchFloatRight {
    display: inline;
    position: relative;
    top: 0px;
    right: -10px;
}

.colorSwatchFloatLeft .picker-button {
    width: 30px;
    height: 30px;
}

.colorSwatchFloatRight .picker-button {
    width: 30px;
    height: 30px;
}

.eyedropper {
    font-size: 12pt;
    color: #000;
}

#modalBackground {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2000;
}

.tooltip {
    z-index: 5000;
}

.impersonate-badge {
    position: absolute;
    bottom: 0;
    left: 20;
    z-index: 1000;
} 

.group-tab .group-name {
    font-weight: bold;
    border: 1px solid #e7e7e7;
    padding: 10px;
}
.claim-selection .claim-active {
    font-weight: bold;
    font-style: italic;
    background-color: #e7e7e7;
}

.group-claims tr {
    cursor: pointer;
}

.group-claims td {
    border-top: 0px !important;
    padding: 5px;
}
.group-tab {
    border: 1px solid #e7e7e7;
    border-top: 0;
    padding: 10px;
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}
.fa-clock-o:before {
    content: "\f017";
  }
 
.filterable-item {
    width: 100%;
    border: 1px solid #CCC;
    margin: 5px;
    padding: 5px;
    vertical-align: middle;
    text-align: center;
    position: relative;
    
}

.filterable-item:hover {
    border: 1px solid #337AB7;
}

.filterable-image {
    width: 100%;
    height: 250px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 0;
}

.filterable-item a:hover {
    text-decoration: none;
}

.filterable-item .overlay-top {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,0.5);
    width: 100%;
    height: 32px;
    overflow: hidden;
    border-bottom: 1px solid #CCC;
    padding: 2px 25px;
    text-overflow: ellipsis;
    font-size: 14px;
    text-align: center;
    z-index: 2;
    @include media-breakpoint-up(xl) { 
        margin-bottom: .5rem;
    }
    > div { 
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    } 
    
}

.filterable-item .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFF;
    opacity: 0.95;
    overflow: hidden;
    width: 100%;
    height: 0px;
    transition: .5s ease;
    border-top: 1px solid #CCC;
}

.filterable-item:hover .overlay {
    height: 35px;
}

/*#collapseCategories div::-webkit-scrollbar {
    width: 5px;
}

#collapseCategories div::-webkit-scrollbar-track {
    background: #E6E6E6;
}

#collapseCategories div::-webkit-scrollbar-thumb {
    background: #B3B3B3;
}

#collapseCategories div::-webkit-scrollbar-thumb:hover {
    background: #555;
}*/

.collapse-categories-content::-webkit-scrollbar {
    width: 5px;
}

.collapse-categories-content::-webkit-scrollbar-track {
    background: #E6E6E6;
}

.collapse-categories-content::-webkit-scrollbar-thumb {
    background: #B3B3B3;
}

.collapse-categories-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.form-check span {
    color: hsl(0, 0%, 60%);
    font-size: 12px;
    padding-right: 20px;
}

#divEditCategories {
    color: hsl(0, 0%, 60%);
    font-size: 12px;
    padding-right: 20px;
    margin-top: 5px;
    margin-bottom: 35px;
}

.filterable-image input {
    
}
.filterable-image .swatch-container { 
    .swatch { 
        width: 15px;
        height: 15px;
    }
    span { 
        display: inline-block;
    }
}
.filterable-item { overflow: hidden; }
.filterable-item .overlay { 
    box-shadow: 0 -2px 3px rgba(0,0,0,0.15); 
    bottom: -10px;
    .swatch { 
        box-shadow: 0 0 3px rgba(0,0,0,0.15);
    }
}
.filterable-item:hover .overlay {
     bottom: 0; 
}
.filterable-image .fi-options { 
    position: relative;
    top: 32px; 
    padding: 5px 0;
    white-space: nowrap;
    input,div { 
        transition: .5s all; 
    }
     input { 
        display: none;
        float: left;
       width: 20px;
       height: 20px; 
        margin-left: 5px;
        margin-top: 3px;
        line-height: 0; 
     }
     div { 
        display: none;
        float: right;
        line-height: 0; 
        font-size: 28px;
        color: #767676;
     }
}
@keyframes option-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
   }

   .fi-checkbox { 
    position: absolute;
    left: 0px; 
    width: 22px;
    height: 22px; 
     line-height: 0; 
     opacity: 0.25; 
     transition: .5s all; 
     &:checked { 
         opacity: 1; 
     }
   }
   .filterable-item:hover { 
       .fi-checkbox { 
           opacity: 1; 
       }
   }
.filterable-image:hover .fi-options { 
    div, input { 
        animation: option-fade-in 1s 1; 
    }

    div{ 
        display: block;
    }
    input { 
        display: block; 
    }
 } 
 .filterable-image .fi-options input:checked { 
     display: inline; 
     animation: none !important; 
 }



span[data-action='onclick']:hover {
    cursor: pointer;
}

.pf-filters label { 
    cursor: pointer; 
    width: auto; 
}
.pf-pagination { 
    justify-content: center; 
    a.page-link { 
        &.prev, &.next { 
            transition: .5s all; 
            opacity: 0.6;
            &:hover {
                opacity: 1; 
            }

        }
    }
    .page-item:first-child .page-link { 
        border-top-left-radius: 5px; 
        border-bottom-left-radius: 5px; 
    }
    .page-item:last-child .page-link { 
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px; 
    }
} 

.img-checkerbox { 
    width: 250px;
    border: 3px dashed rgb(204, 204, 204);
    background-image: linear-gradient(45deg, #EEEEEE 25%, transparent 25%), linear-gradient(-45deg, #EEEEEE 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #EEEEEE 75%), linear-gradient(-45deg, transparent 75%, #EEEEEE 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.pagination > .active > a {
    background-color: rgb(204, 204, 204);
    border: solid 1px rgb(204, 204, 204);
} 
.page-item.active .page-link { 
    z-index: 3;
    color: #fff;
    background-color: rgb(204, 204, 204);
    border: solid 1px rgb(204, 204, 204);
}

.breadcrumb-container { 
    position: relative;
    .breadcrumb-help { 
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 12px;
        color: #999;
        cursor: pointer;
    }
}
.help-modal { 
    &.animate-in { 
        .modal { 
            right: -50%; 
        }
    }
    .modal { 
        display: block; 
        left: auto;
        width: 50%; 
        top: 0; 
        bottom: 0; 
        right: 1%; 
        .modal-content { 
            height: 90vh; 
        }
        .modal-dialog { 
            max-width: none !important; 
        }
        transition: all .5s; 
        .show-expanded { 
            display: none; 
        }
    }
    .icons { 
        position: absolute;right: 1rem;top: 1rem; 
        
        i { 
            color: #666;
            cursor: pointer; 
            &:hover { 
                color: black;
            }
        }
    }
    .modal-body.loading { 
       background-image: url('//dynamic-cdn.azureedge.net/theme/Shared/images/ajax-loader.gif');
       background-position: center center;
       background-repeat: no-repeat; 
    }
    .modal-body { 
        height: 100%;
        overflow: auto;
        scrollbar-gutter: stable;
        scroll-behavior: smooth;
        &::-webkit-scrollbar { 
            width: 13px; 
    
        } 
        &::-webkit-scrollbar-thumb { 
            background-color: #ddd;
            border-radius: 100px;
        }
    }
    &.expanded { 
        .modal { 
            width: 90%; 
        }
        .show-expanded { 
            display: inline-block; 
        }
        .hide-expanded { 
            display: none; 
        } 
    }
}
#sidebar-nav { 
    @include media-breakpoint-down(md) { 
        width: 200px !important; 
        padding-left: 1rem; 
        a { 
//            font-size: 0;
        }
        i { 
            font-size: 1rem; 
        }
        .tooltip-data i { 
            font-size: 1rem; 
        }
        .nav-item ul  { 
            padding-left: 2rem !important; 
            a { 
                font-size: 1rem; 
                margin: 0 !important; 
                padding: 0 !important; 
            }
        }
    }
}
#partner-content { 
    @include media-breakpoint-only(md) { 
        margin-left: 200px !important; 
    }
}
#help-drawer {
    .modal-body { 
        padding: 0; 
    }
    h1, h2, h3, h4 { 
        font-style: normal; 
        padding-bottom: 1rem; 
        font-weight: normal;  
      
    }
    h1 { 
        font-size: 32px; 
    }
    h2 { 
        font-size: 28px; 
    }
    h3 { 
        font-size: 22px; 

    }
    .hs-embed-wrapper{ 
        max-width: none; 
    }
    img { 
        max-width: 100%; 
    }

}
.page-link { 
    color: black; 
    &:hover { 
        color: #5a5a5a;
    background-color: #eee;
    border-color: #ddd;
    }
}
@import '../../admin-v3/sass/editor.scss';
@import 'page-snippets.scss'; 