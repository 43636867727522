@import './bootstrap-functions.scss'; 

$sidebarWidth: 250px; 
.material-symbols-sharp {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
.fui-openclose-btn { 
    display: none; 
    position: absolute;
    right: 0;  
    border: 0;
    background-color: transparent; 
    padding: 0 .5rem;
    margin: 0;
    font-size: 1.5rem; 
    font-weight: bold; 
    top: 5px; 
    cursor: pointer; 
    line-height: 0;
    opacity: .75;
    &:hover { 
        opacity: 1; 
    }
    span { 
        font-size: 1.75rem;    
    }
    &:focus { 
        outline: 0 !important; 
    }
    span { 
        transform-origin: center;;
        display: block; 
        position: relative; 
    }
    &.open span { 

        
    }
    &.closed span { 

    }
}
.fui-
span {
    font-size: 1.25rem;    
}label { 
    position: relative;
    width: 100%; 
}
.editor-sidebar { 
  overflow-y: auto;  
  height: 600px;
  padding-right: 1rem; 
    padding-left: 1rem; 
    scrollbar-gutter: stable;
    scroll-behavior: smooth;
    &::-webkit-scrollbar { 
        width: 13px; 

    } 
    &::-webkit-scrollbar-thumb { 
        background-color: #ddd;
        border-radius: 100px;
    }
.btn-close { 
    font-size: 1.5rem;
    border-radius: 100px;
    line-height: 0;
    padding-top: 0.75rem;
    padding-bottom: 1rem;
    background-color: transparent;
    color: #aaa;
    border:1px solid #aaa; 
    outline: none; 
    cursor: pointer; 
    &:hover { 
        background-color: #aaa;
        color: white; 
    }
}
.fui-label { 
    font-size: .9rem;
    font-weight: bold; 
    padding-bottom: 0.25rem; 
    padding-top: .25rem; 
       // border-bottom: 1px solid rgba(0,0,0, 0.2);

}

.editor-control.expanded { 
    //padding-bottom: 1.5rem;
     /* border-bottom: 1px solid #aaa; */
    //margin-bottom: 1rem;
}
.fui-color, .fui-color-preview { 
    width: 15px;
    height: 15px; 
}
.fui-color { 
    position: absolute;
    top: 0; 
    right: 0; 
  opacity: 0;
}
.fui-color-preview { 
    display:block; 
    float: right; 
    border-radius:25px; 
}
.fui-color-duo { 
    position: relative;
}
.input-group { 
    margin-top: .5rem;
    margin-bottom: .5rem; 
}
.btn.add-filters { 
    width: 100%; 
}
.scrollbody { 
    overflow-y: auto;  
    height: calc(100vh - 200px); 
    padding-right: 1rem; 
    padding-left: 1rem; 
    scrollbar-gutter: stable;
    scroll-behavior: smooth;
    &::-webkit-scrollbar { 
        width: 13px; 

    }
    &::-webkit-scrollbar-thumb { 
        background-color: #ddd;
        border-radius: 100px;
    }
}
.modal-content.sidebar-popover { 
    position: absolute;
    top: 0;
    z-index: 2;
    background-color: white;
    box-shadow: 0 4px 4px rgba(0,0,0, 0.25);

    .sidebar-text { 
        width: 100%;
        text-overflow:ellipsis;
        overflow: hidden; 
        text-align: center; 
    }
}

.toggle {
    cursor: pointer;
    display: inline-block;
  }
  
  .toggle-switch {
    display: inline-block;
    background: #ccc;
    border-radius: 16px;
    margin-top: 5px; 
    width: 44px;
    height: 24px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
    
  }
  .toggle-switch:before, .toggle-switch:after {
    content: "";
  }
  .toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 18px;
    height: 18px;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: left 0.25s;
  }
  .toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  }
  .toggle-checkbox:checked + .toggle-switch {
    background: #007bff;
  }
  .toggle-checkbox:checked + .toggle-switch:before {
    left: 23px;
  }
  
  .toggle-checkbox {
    position: absolute;
    visibility: hidden;
  }
  
  .toggle-label {
    margin-left: 0;
    position: relative;
    top: 2px;
  }
  .upload-zone { 
     min-height: 200px; 
     border: 1px solid #aaa;
    box-shadow: 0 2px 2px rgba(0,0,0,0.25); 
    text-align: center; 
  }
  .upload-preview { 
    margin-top: 1rem; 
    i { 
      cursor: pointer; 
    }
    img { 
      padding-bottom: .5rem;
    }
    padding: .5rem; 
    border: 1px solid #aaa; 
    box-shadow: 0 2px 2px rgba(0,0,0,0.25); 
  }
  .clipart-gallery { 
    list-style-type: none;
    display:flex;
    flex-wrap: wrap; 
    padding: 0;
    margin: 0; 
    height:  600px; // calc(100vh - 300px); 
    overflow-y: auto;
    scrollbar-gutter: stable;
    scroll-behavior: smooth;
    margin-top: 1rem; 
    &::-webkit-scrollbar { 
        width: 13px; 

    }
    &::-webkit-scrollbar-thumb { 
        background-color: #ddd;
        border-radius: 100px;
    }
    li { 
      flex: 0 0 auto; 
    width: 33%;
    margin-bottom: 1rem;  
    img {
       width: 100%; 
    } 
  }
  }
  .clipart-category-list { 
    list-style-type: none;
    padding: 0;
    margin: 0; 
    li.category-listing { 
      padding: .5rem; 
      cursor: pointer; 
      transition: .5s all;
      border-bottom: 1px solid #ddd; 
      .material-icons { 
        line-height: 0;
        position: relative;
        top: 5px;
      }
      &:hover { 
        background-color: #eee; 
      }
    }
    &.loading { 
      li.category-listing { 
        opacity: 0; 
        &.clicked { 
          opacity: 1; 
        }
      }
    }
  }
  .fui-body { 
    label, .fui-field-label { 
      font-size: .9rem;
      color: #333; 
      text-transform: uppercase;  
    }

  }
  
  .sidebar-panel.editor-control.expanded  { 
    border-bottom: 1px solid #ddd; 
    padding-top: 5px;
    padding-bottom: .5rem;
    margin-bottom: 1rem; 
  }
} 
.canvas-wrapper { 
  border: none !important; 

}
.canvas-container { 
  border: none !important;
  box-shadow: 1px 3px 5px rgba(0,0, 0, 0.5);
  margin-bottom: 2rem; 
}
.btn-fui { 
  background-color: white;
  border: 1px solid #666;
  box-shadow: 0 3px 3px rgba(0,0,0, 0.1);
  color: #666; 
  &.btn-zoom i { 
    font-size: 2rem; 
  }
}
.drag-layer { 
  border-bottom: 1px solid #ddd;
  .drag-handle { 
    color: #aaa; 
    float:right;
    &:hover { 
      color: #333;
    }
  }
  &:last-child { 
    border-bottom: 0; 
  }
}