#claim-card::-webkit-scrollbar-track {
    background-color: #F5F5F5;
  }

  #claim-card::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
  }

  #claim-card::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0,0,0,.25);
  }