.group-add-subcategory:before {
    content: "\f055";
}
.group-reporting:before {
    content: "\f200";
}
.group-payments:before {
    content: "\f155";
}
.group-orders:before {
    content: "\f07a";
}
.group-dashboard:before {
    content: "\f3fd";
}
.group-administration:before {
    content: "\f0eb";
}
.group-ad-placements:before {
    content: "\f108";
}
.group-widgets:before {
    content: "\f10a";
}
.group-store-mapping:before {
    content: "\f0ce";
}
.group-email:before {
    content: "\f0e0";
}
.group-accounts:before,
.group-partner:before,
.group-partners:before,
.group-users:before {
    content: "\f0c0";
}
.group-settings:before {
    content: "\f085";
}
.group-product-management:before,
.group-products:before {
    content: "\f553";
}
.group-design-management:before,
.group-designs:before,
.group-theme:before {
    content: "\f1fc";
}
.group-promotions:before {
    content: "\f06b";
}
.group-content:before {
    content: "\f15c";
}
.group-outgoing-emails:before {
    content: "\f1d8";
}
.group-shops:before {
    content: "\f09d";
}
.group-marketing:before {
    content: "\f0a1";
}
.group-custom-domains:before,
.group-website:before {
    content: "\f0ac";
}
.group-data-exports:before,
.group-exports:before {
    content: "\f381";
}
.group-special:before {
    content: "\f069";
}
.group-impersonate:before {
    content: "\f06e";
}
.group-categories:before,
.group-subcategories:before {
    content: "\f0e8";
}
group-commissions:before {
    content: "\f53a";
}
group-assets:before {
    content: "\f574";
}
.group-home:before {
    content: "\f015"
  }
.glyphicon.arrow:before {
    content: "";
}
.active > a > .glyphicon.arrow:before {
    content: "";
}
.fa.arrow:before {
    content: "";
}
.active > a > .fa.arrow:before {
    content: "";
}
.fa.plus-minus:before,
.fa.plus-times:before {
    content: "";
}
.active > a > .fa.plus-minus:before {
    content: "";
}
.active > a > .fa.plus-times {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
