.status-unconfirmed:before {
  content: "\f111";
  color: #FFEA00;
}

.status-locked:before {
  content: "\f111";
  color: #FF0000;
}

.status-active:before {
  content: "\f111";
  color: #00FF00;
}
