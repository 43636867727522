body {
    background-color: #FFF;
    font-family: Roboto;
  }

  .toggle-group label {
    width: initial;
  }

  #sidebar-nav {
    margin-top: 0px;
    position: fixed;
    height: 100%;
    width: 300px;
    padding: 0px 15px;
  }

    #sidebar-nav a {
      color: #000;
    }

      #sidebar-nav a:hover {
        background-color: rgba(0,0,0,.025);
        border-radius: 10px;
      }

    #sidebar-nav .nav-active {
      background-color: rgba(0,0,0,.025);
    }

  .sidebar ul li {
    border-bottom: 0px !important;
  }

  #partner-content {
    width: 100%;
    /*margin-top: 0px !important;*/
  }

  @media screen and (min-width: 768px) {
    #partner-content {
      margin-left: 300px;
      margin-top: 0px !important;
    }
  }

  @media screen and (max-width: 767px) {
    #partner-content {
      margin-top: 45px !important;
    }
  }

  .dropdown-toggle::after {
    border: none !important;
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f107" !important; /* the desired FontAwesome icon */
    vertical-align: 0; /* to center vertically */
  }

  li.nav-item.show a.dropdown-toggle::after {
    content: "\f106" !important /* the different icon */
  }

  @media screen and (max-width: 767px) {
    li.nav-item {
      width: 100%;
    }
  }

  .sidebar .sidebar-search {
    padding: 0 0 0.5rem 0;
  }

  a#profile-link:hover {
    text-decoration: none;
  }

  .nav-spacer {
    margin-top: auto;
    border-top: 1px solid #e7e7e7;
  }

  .nav-separator {
    border-top: 1px solid #e7e7e7;
  }

  .nav-subitem a {
    border-left: 1px solid #e7e7e7;
    margin-left: 20px;
    padding-left: 20px !important;
    border-radius: 0px 10px 10px 0px !important;
  }

  .breadcrumb {
    background-color: rgba(0,0,0,.025);
  }

  .breadcrumb-item a {
    color: #000;
  }

  .clickable-row {
    cursor: pointer;
  }

  .menu-icon {
    color: rgba(0,0,0,.8);
  }

  .navbar-mobile {
    background-color: #FFF;
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
  }

    .navbar-mobile button {
      border: 1px solid #CCC;
    }

  #mobile-navbar {
    background-color: #FFF;
  }

  .navbar-collapse {
    align-items: start;
  }

  .main-overlay {
    position: fixed;
    display: none;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.8);
    z-index: 99;
  }

  .scrollbody {
    overflow-y: auto;
    height: calc(100vh - 48px);
    padding-right: 1rem;
    padding-left: 1rem;
    scrollbar-gutter: stable;
    scroll-behavior: smooth;
  }

    .scrollbody::-webkit-scrollbar {
      width: 13px;
    }

    .scrollbody::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 100px;
    }

  .blur-wrapper {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    pointer-events: none;
  }

  .onboarding-header-card {
    text-transform: uppercase;
    max-width: 1200px;
    margin-bottom: 5px;
    color: #337ab7;
    /*color: #000;*/
    font-size: 1.25rem;
    font-weight: bold;
  }

  .onboarding-card {
    max-width: 1000px;
    margin-bottom: 20px;
  }

  .onboarding-header {
    color: #337ab7;
    /*color: #000;*/
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .onboarding-icon {
    display: inline-block;
    line-height: 0px;
    border-radius: 50%;
    background-color: #337ab7;
    border: 3px solid #337ab7;
    color: white;
    font-size: 40px;
    font-weight: bold;
    margin: 5px 25px 0px 10px;
    height: 55px;
    width: 55px;
  }

  .onboarding-icon-completed {
    background-color: #119F0E;
    border: 3px solid #119F0E;
  }

  .onboarding-icon-disabled {
    color: #D3D8DB;
    background-color: #FFF;
    border: 3px solid #D3D8DB;
  }

  .onboarding-icon i {
    font-size: 32px;
    display: inline-block;
    margin: 8px 5px;
  }

  .onboarding-icon span {
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
    margin-left: 13px;
    margin-right: 13px;
  }

  .onboarding-separator {
    margin: 15px 0px 15px 35px;
    height: 40px;
  }

    .onboarding-separator.Or {
      width: 100%;
      text-align: center;
      height: auto;
      color: #337ab7;
      font-size: 1.5rem;
      font-weight: bold;
      margin: 10px auto;
    }

    .onboarding-separator.Spacer {
      content: "";
      width: 3px;
      height: 60px;
    }

    .onboarding-separator.Blue {
      content: "";
      width: 3px;
      background-color: #337ab7;
      border-radius: 10px;
    }

    .onboarding-separator.Green {
      content: "";
      width: 3px;
      background-color: #119F0E;
      border-radius: 10px;
    }

    .onboarding-separator.Gray {
      content: "";
      width: 3px;
      background-color: #D3D8DB;
      border-radius: 10px;
    }

    .onboarding-separator.Gradient {
      content: "";
      width: 3px;
      background: linear-gradient(to bottom, #119F0E, #337ab7);
      border-radius: 10px;
    }

  .onboarding-category-horizontal {
    padding: 10px 20px;
    border: 1px solid rgba(51, 122, 183, 0.5);
    border-radius: 5px;
    height: 100%;
  }

    .onboarding-category-horizontal a:hover {
      text-decoration: none;
    }

  .onboarding-category {
    padding: 20px 0px;
  }

    .onboarding-category a:hover {
      text-decoration: none;
    }

  .onboarding-help {
    font-style: italic;
    font-size: 0.80rem;
    text-align: right;
    margin-top: 10px;
  }

    .onboarding-help a {
      color: #6c757d !important;
    }

      .onboarding-help a:hover {
        text-decoration: underline;
      }

  .onboarding-category:hover .shake-icon {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  .onboarding-category:hover .rocket-fire {
    display: flex;
  }

  .onboarding-task:hover {
    border-color: #337ab7;
    cursor: pointer;
  }

  .text-disabled {
    color: #D3D8DB;
  }

  .rocket-fire {
    display: none;
    font-size: 18px;
    position: relative;
    top: -55px;
    left: -40px;
    color: red;
    transform: rotate(220deg);
  }

  @keyframes shake {
    0% {
      transform: translate(0px, 0px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -1px) rotate(-1deg);
    }

    20% {
      transform: translate(0px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(0px, 0px) rotate(0deg);
    }

    40% {
      transform: translate(0px, 0px) rotate(1deg);
    }

    50% {
      transform: translate(0px, 0px) rotate(-1deg);
    }

    60% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(0px, 0px) rotate(-1deg);
    }

    80% {
      transform: translate(0px, 0px) rotate(1deg);
    }

    90% {
      transform: translate(0px, 0px) rotate(0deg);
    }

    100% {
      transform: translate(0px, 0px) rotate(-1deg);
    }
  }

  .row-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .disabled {
    cursor: not-allowed;
  }

  div.dataTables_filter {
    float: right;
  }

  .clickable {
    cursor: pointer;
  }

  .group-home:before {
    content: "\f015"
  }

  .group-my-shops:before {
    content: "\f0e8"
  }

  .el-highlight {
    /*outline: 1px solid #337ab7 !important;*/
    /*background-image: linear-gradient(90deg, rgba(0,109,176,.25) 0%, rgba(255,255,255,0) 75%);*/
    background: linear-gradient(90deg, rgba(0,109,176,.15) 0%, rgba(255,255,255,0) 75%);
    background-size: 600% 600%;
    animation: nav-gradient 1.5s ease infinite;
    /*outline: 2px solid rgba(51, 122, 183, 0.5) !important;*/
  }

  @keyframes nav-gradient {
    0% {
      background-position: 100% 50%;
    }

    50% {
      background-position: 0% 50%;
    }

    100% {
      background-position: 100% 50%;
    }
  }

  /*#help-drawer {
    position: absolute;
    top: 70px;
    right: 0px;
    width: 50%;
    height: calc(100% - 130px);
    background-color: #FFF;
    border: 1px solid #CCC;
    border-radius: 5px 0px 0px 5px;
    z-index: 99;
    overflow-y: auto;
  }*/

  #floating-help {
    position: fixed;
    right: 0;
    top: -10px;
    margin: 20px;
    padding: 10px 20px;
    border: 1px solid #CCC;
    border-radius: 5px;
    background-color: #FFF;
    z-index: 1000;
    width: 350px;
    overflow: hidden;
  }