
.rs-handle {
    background-color: #f3f3f3;
    box-shadow: 0px 0px 4px 0px #000;
  }

  .rs-tooltip-text {
    font-size: 2rem;
    font-weight: bold;
    font-family: inherit;
    top: 45px;
  }

  .rs-edit {
    height: 60px;
  }

  .rs-hover {
    height: 60px;
  }

  .rs-input {
    height: 60px !important;
  }

  .select2-selection--single {
    padding: 5px;
    height: 40px !important;
    border: var(--radio-border-width) solid var(--color-gray) !important;
  }

  .select2-selection__arrow {
    height: 40px !important;
    width: 35px !important;
  }

  .select2-selection__rendered {
    /*color: var(--color-blue) !important;*/
  }

  .img-flag {
    width: 22px;
    margin: -5px 5px 0px 5px;
  }

  .radio-card {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .color-box {
    width: 120px;
    text-align: center;
    border: 2px solid #E2EBF6;
    margin: 5px;
    padding: 10px 0px 5px 0px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

    .color-box:hover {
      /*border-color: var(--color-blue);*/
      box-shadow: 3px 3px 5px rgba(0,0,0,.15);
    }

  .color-check {
    display: none;
  }

    .color-check:checked + .color-box {
      border-color: var(--color-blue);
    }